import { ListViewColumnStyle } from '~/src/components/shared/features/types'

import colors from '~/config/colorsScheme'

import {
  LegStatus,
  LoadStatus,
  DocumentType,
  TrackingStatus,
  ShipmentStatus,
  StopLatenessStatus,
} from '~/__generated_types__/globalTypes'

export const STATUS_COLORS = {
  GREEN: 'bg-green-50 text-green-700',
  YELLOW: 'bg-yellow-50 text-yellow-900',
  RED: 'bg-red-50 text-red-700',
  GRAY: 'bg-gray-50',
  BLUE: 'bg-blue-50 text-blue-800',
}

export const STATUS_TO_COLOR_MAP = new Map<
  LoadStatus | LegStatus | TrackingStatus | ShipmentStatus,
  string
>([
  [LoadStatus.Started, STATUS_COLORS.GREEN],
  [LoadStatus.Dispatched, STATUS_COLORS.GREEN],
  [LoadStatus.Planned, STATUS_COLORS.YELLOW],
  [LoadStatus.Imported, STATUS_COLORS.BLUE],
  [LoadStatus.Suspended, STATUS_COLORS.RED],
  [LoadStatus.Completed, STATUS_COLORS.BLUE],
  [LoadStatus.Available, STATUS_COLORS.YELLOW],
  [LoadStatus.Cancelled, STATUS_COLORS.GRAY],
  [LoadStatus.InAudit, STATUS_COLORS.BLUE],
  [TrackingStatus.EnRoute, STATUS_COLORS.GREEN],
  [TrackingStatus.Completed, STATUS_COLORS.BLUE],
  [TrackingStatus.InProgress, STATUS_COLORS.GREEN],
  [TrackingStatus.Planned, STATUS_COLORS.YELLOW],
  [ShipmentStatus.Open, STATUS_COLORS.YELLOW],
  [ShipmentStatus.OnHold, STATUS_COLORS.YELLOW],
  [ShipmentStatus.Planned, STATUS_COLORS.YELLOW],
  [ShipmentStatus.PickedUp, STATUS_COLORS.GREEN],
  [ShipmentStatus.Delivered, STATUS_COLORS.BLUE],
  [ShipmentStatus.Cancelled, STATUS_COLORS.GRAY],
  [ShipmentStatus.Unknown, STATUS_COLORS.GRAY],
])

export const OPERATOR_LEG_LIST_VIEW_HEADER: Record<string, ListViewColumnStyle> = {
  leg: { name: 'Legs', className: 'px-8 w-[17%]' },
  idx: { name: 'Stop', className: 'w-[7%]' },
  location: { name: 'Location', className: 'w-[20%]' },
  pod: { name: 'PoD', className: 'w-[5%] normal-case' },
  app: { name: 'Appointment', className: 'w-[13.5%]' },
  inout: { name: 'In/Out', className: 'w-[13%]' },
  miles: { name: 'Miles', className: 'w-[6%]' },
  transit: { name: 'Transit', className: 'w-[9%]' },
  dwell: { name: 'Dwell', className: 'w-[9.5%]' },
}

export const OPERATOR_LEG_LIST_VIEW_BODY: Record<string, { className?: string }> = {
  leg: { className: 'w-[19%]' },
  stop: { className: 'w-[8.75%]' },
  location: { className: 'pr-8 w-[24%]' },
  pod: { className: 'w-[6%]' },
  app: { className: 'w-[16.5%]' },
  inout: { className: 'w-[16.5%]' },
  miles: { className: 'w-[7.5%]' },
  transit: { className: 'w-[10%]' },
  dwell: { className: 'w-[11%]' },
}

export const CUSTOMER_LEG_LIST_VIEW_HEADER: Record<string, ListViewColumnStyle> = {
  leg: { name: 'Legs', className: 'px-8 w-[17%]' },
  idx: { name: 'Stop', className: 'w-[7%]' },
  location: { name: 'Location', className: 'w-[20%]' },
  pod: { name: 'PoD', className: 'w-[4%] normal-case' },
  app: { name: 'Appointment', className: 'w-[13.5%]' },
  inout: { name: 'In/Out', className: 'w-[14%]' },
  miles: { name: 'Miles', className: 'w-[6.5%]' },
  transit: { name: 'Transit', className: 'w-[8%]' },
  dwell: { name: 'Dwell', className: 'w-[6.5%]' },
  sharing: { name: '', className: 'w-[2%]' },
}

export const CUSTOMER_LEG_LIST_VIEW_BODY: Record<string, { className?: string }> = {
  leg: { className: 'w-[19%]' },
  stop: { className: 'w-[8.75%]' },
  location: { className: 'pr-8 w-[24%]' },
  pod: { className: 'w-[5%]' },
  app: { className: 'w-[16%]' },
  inout: { className: 'w-[17.5%]' },
  miles: { className: 'w-[7.5%]' },
  transit: { className: 'w-[10%]' },
  dwell: { className: 'w-[7.75%]' },
  sharing: { className: 'w-[4%]' },
}

export const FILTER_DOCUMENT_TYPES: Array<DocumentType> = [
  DocumentType.ProofOfDelivery,
  DocumentType.BillOfLading,
  DocumentType.Tender,
  DocumentType.Other,
]

export const ONE_DAY_IN_MINUTES = 24 * 60
export const LOWER_TIME_BOUND = 14
export const UPLOADED_SORT_PREFIX = 'UPLOADED'

// TODO add support for specifying background, border and text tailwind classes
// in this constant - https://app.asana.com/0/1206956035407847/1207707746630599/f
export const LEG_COLORS = [
  {
    twColor: 'blue-500',
    hexColor: colors.blue[500],
  },
  {
    twColor: 'darkPink-500',
    hexColor: colors.darkPink[500],
  },
  {
    twColor: 'bloodOrange-500',
    hexColor: colors.bloodOrange[500],
  },
  {
    twColor: 'green-500',
    hexColor: colors.green[500],
  },
  {
    twColor: 'brightPurple-500',
    hexColor: colors.brightPurple[500],
  },
  {
    twColor: 'brightPink-500',
    hexColor: colors.brightPink[500],
  },
  {
    twColor: 'lightBlue-500',
    hexColor: colors.lightBlue[500],
  },
  {
    twColor: 'mediumPurple-500',
    hexColor: colors.mediumPurple[500],
  },
  {
    twColor: 'lime-500',
    hexColor: colors.lime[500],
  },
  {
    twColor: 'darkBlue-500',
    hexColor: colors.darkBlue[500],
  },
  {
    twColor: 'orange-500',
    hexColor: colors.orange[500],
  },
  {
    twColor: 'teal-500',
    hexColor: colors.teal[500],
  },
]

export const LEG_COLORS_HOVER = [
  {
    twColor: 'bg-blue-700',
    hexColor: colors.blue[700],
  },
  {
    twColor: 'bg-darkPink-700',
    hexColor: colors.darkPink[700],
  },
  {
    twColor: 'bg-bloodOrange-700',
    hexColor: colors.bloodOrange[700],
  },
  {
    twColor: 'bg-green-700',
    hexColor: colors.green[700],
  },
  {
    twColor: 'bg-brightPurple-700',
    hexColor: colors.brightPurple[700],
  },
  {
    twColor: 'bg-brightPink-700',
    hexColor: colors.brightPink[700],
  },
  {
    twColor: 'bg-lightBlue-700',
    hexColor: colors.lightBlue[700],
  },
  {
    twColor: 'bg-mediumPurple-700',
    hexColor: colors.mediumPurple[700],
  },
  {
    twColor: 'bg-lime-700',
    hexColor: colors.lime[700],
  },
  {
    twColor: 'bg-darkBlue-700',
    hexColor: colors.darkBlue[700],
  },
  {
    twColor: 'bg-orange-700',
    hexColor: colors.orange[700],
  },
  {
    twColor: 'bg-teal-700',
    hexColor: colors.teal[700],
  },
]

export const DOCUMENT_TYPE_LABELS: Record<DocumentType, string> = {
  [DocumentType.BillOfLading]: 'Bill of lading',
  [DocumentType.Cargo]: 'Cargo',
  [DocumentType.ExtraTripSlip]: 'Extra trip slip',
  [DocumentType.FineReceipt]: 'Fine receipt',
  [DocumentType.FuelReceipt]: 'Fuel receipt',
  [DocumentType.LateSlip]: 'Late slip',
  [DocumentType.LumperReceipt]: 'Lumper Receipt',
  [DocumentType.Miscellaneous]: 'Miscellaneous',
  [DocumentType.Osd]: 'OSD',
  [DocumentType.Other]: 'Other',
  [DocumentType.Photograph]: 'Photograph',
  [DocumentType.PortTicket]: 'Port ticket',
  [DocumentType.ProofOfDelivery]: 'Proof of delivery',
  [DocumentType.TripSheet]: 'Trip sheet',
  [DocumentType.Tender]: 'Tender',
  [DocumentType.WfxReceipt]: 'WFX receipt',
  [DocumentType.LoadQuality]: 'Load quality',
  [DocumentType.Mb]: 'MB',
  [DocumentType.PortTicketInOut]: 'Port Ticket In Out',
  [DocumentType.Return]: 'Return',
  [DocumentType.WeightReceipt]: 'Weight Receipt',
}

export const TOOLTIPS_CUSTOMER: Record<string, string> = {
  dwell: 'Time spent at the location (hrs:min)',
  inout: 'Arrival and departure times, in your time zone',
  transit: 'Travel time to the location (hrs:min)',
}

export const TOOLTIPS_OPERATORS: Record<string, string> = {
  inout: 'Arrival and departure times, in your time zone',
  transit:
    'Transit: Travel time to the location (hrs:min) Dwell: Time spent at the location (hrs:min)',
  app: 'Appointments tell the driver when they are expected to arrive at the delivery or pickup location',
  criticalApp: 'Missing this appointment can result in penalties',
}

export const INTERVAL_TIME = 1000 * 60 * 5 // every 5 mins (milliseconds)
export const TWENTY_FOUR_HOURS_IN_MILLISECONDS = 24 * 60 * 60 * 1000

export const DEFAULT_TIME_ZONE_NAME = 'America/New_York'
export const DEFAULT_TIME_ZONE_CODE = 'ET'

export const MAX_NUMBER_COST_CENTERS = 15

export const NOT_SHARABLE_LOAD_STATUS = new Set([
  LoadStatus.Planned,
  LoadStatus.Available,
  LoadStatus.Cancelled,
])

export const NOT_SHARABLE_LEG_STATUS = new Set([
  LegStatus.Available,
  LegStatus.Planned,
  LegStatus.Unknown,
])

export const UNREAD_NOTIFICATION_COUNT_THRESHOLD = 99

export const MILLISECONDS_TO_HOURS = 1000 * 60 * 60

export const OPERATIONAL_STOP = 'Operational Stop'

export const LATENESS_STATUS_COLORS = {
  [StopLatenessStatus.RunningLate]: 'bg-yellow-600',
  [StopLatenessStatus.Late]: 'bg-red-600',
}
